import { useParams } from '@solidjs/router'
import SubscriptionGate from '~/components/subscription-gate/subscription-gate'
import ValidationView from '~/components/validation-view/validation-view'
import { ValidationParams } from '~/types/routes/series/validation'

export default function Validation() {
  const { id } = useParams<ValidationParams>()
  return (
    <SubscriptionGate requiredRole='validation'>
      <ValidationView chapterId={id} />
    </SubscriptionGate>
  )
}
